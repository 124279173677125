import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { FavouriteAddress, FavouriteAddressFull } from '../models/favourite-addresses.model';
import { ApiResponse } from '@app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getFavouriteAddresses(): Observable<FavouriteAddressFull[]> {
        return this.httpClient.get<ApiResponse<FavouriteAddressFull[]>>('partner/favourite-address').pipe(map((response) => response.data));
    }

    saveFavouriteAddress(name: string, searchValue: string, address: FavouriteAddress): Observable<FavouriteAddressFull> {
        return this.httpClient
            .post<ApiResponse<FavouriteAddressFull>>('partner/favourite-address', {
                name: name,
                searchValue: searchValue,
                address: address,
            })
            .pipe(map((response) => response.data));
    }

    updateFavouriteAddress(id: string, name: string, searchValue: string, address: FavouriteAddress): Observable<FavouriteAddress> {
        return this.httpClient
            .put<ApiResponse<FavouriteAddress>>(`partner/favourite-address/${id}`, {
                name: name,
                searchValue: searchValue,
                address: address,
            })
            .pipe(map((response) => response.data));
    }

    deleteFavouriteAddress(id: string): Observable<unknown> {
        return this.httpClient.delete<ApiResponse<unknown>>(`partner/favourite-address/${id}`).pipe(map((response) => response.data));
    }
}
