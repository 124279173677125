import { AfterViewInit, Component, Input } from '@angular/core';
import { MapComponent, MapService } from '@app/shared/map';
import { GeoPositionModel } from '@app/store/planning/models';

@Component({
    selector: 'app-form-address-map',
    styleUrls: ['./map.component.scss'],
    templateUrl: './map.component.html',
    providers: [MapService],
})
export class FormAddressMapComponent extends MapComponent implements AfterViewInit {
    @Input({ required: true }) geoPosition!: GeoPositionModel;
    override enableUI = false;

    override ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.mapService.H_MAP.setCenter({ lat: this.geoPosition.latitude || 0, lng: this.geoPosition.longitude || 0 });

        const marker = new H.map.Marker({ lat: this.geoPosition.latitude || 0, lng: this.geoPosition.longitude || 0 });
        this.mapService.H_MAP.addObject(marker);
    }
}
