import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FavouriteAddressFull, FavouriteAddressesFacade } from '@app/store/favourite-addresses';

@Component({
    selector: 'app-popup-list-item',
    templateUrl: './popup-list-item.component.html',
    styleUrls: ['./popup-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupListItemComponent {
    private readonly favouriteAddressesFacade = inject(FavouriteAddressesFacade);

    @Input() favouriteAddress!: FavouriteAddressFull;

    @Output() selectClicked = new EventEmitter<FavouriteAddressFull>();
    @Output() deleteClicked = new EventEmitter<FavouriteAddressFull>();

    form = this.formBuilder.group({
        newName: this.formBuilder.control(null, { validators: [Validators.required] }),
    });

    isClosed = true;

    constructor(private readonly formBuilder: FormBuilder) {}

    onSelectClick(event: Event): void {
        event.preventDefault();
        this.selectClicked.emit(this.favouriteAddress);
    }

    onMoreClick(event: Event): void {
        event.preventDefault();
        this.isClosed = !this.isClosed;
    }

    onChangeNameClick(event: MouseEvent) {
        event.preventDefault();
        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.favouriteAddressesFacade.updateFavouriteAddress(
                this.favouriteAddress.id ?? '',
                this.form.controls.newName.value ?? '',
                this.favouriteAddress.searchValue,
                this.favouriteAddress.address,
            );
        }
    }

    onDeleteClick(event: Event): void {
        event.preventDefault();
        this.deleteClicked.emit(this.favouriteAddress);
    }
}
