<div class="d-flex align-items-center">
    <p-button
        (click)="openSaveFavouriteAddressPopup()"
        type="button"
        [disabled]="!canAddressBeAddedToFavourites"
        [class.p-disabled]="!canAddressBeAddedToFavourites"
        [appTooltip]="'Dodaj adres do ulubionych'"
        [text]="true"
        [rounded]="true"
        icon="pi pi-star"
    />
    <p-button
        (click)="openManageFavouriteAddressPopup()"
        type="button"
        [disabled]="(isAnyFavouriteAddressAdded$ | async) === false"
        [class.p-disabled]="(isAnyFavouriteAddressAdded$ | async) === false"
        [appTooltip]="'Zarządzaj ulubionymi adresami'"
        [rounded]="true"
        icon="pi pi-pencil"
    />
</div>
