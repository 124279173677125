import { Pipe, PipeTransform } from '@angular/core';
import { AutosuggestItem } from '@app/store/heremaps';

export interface HereGeocodeHighlightPositionModel {
    start: number;
    end: number;
}

@Pipe({
    name: 'autosuggesstAddress',
})
export class AutosuggestAddressPipe implements PipeTransform {
    transform(item: AutosuggestItem): string {
        const address = {
            city: this.decorate(item.address.city, item.highlights?.address.city ?? []),
            countryName: this.decorate(item.address.countryName, item.highlights?.address.countryName ?? []),
            county: this.decorate(item.address.county, item.highlights?.address.county ?? []),
            district: this.decorate(item.address.district, item.highlights?.address.district ?? []),
            houseNumber: this.decorate(item.address.houseNumber, item.highlights?.address.houseNumber ?? []),
            label: this.decorate(item.address.label, item.highlights?.address.label ?? []),
            postalCode: this.decorate(item.address.postalCode, item.highlights?.address.postalCode ?? []),
            state: this.decorate(item.address.state, item.highlights?.address.state ?? []),
            street: this.decorate(item.address.street, item.highlights?.address.street ?? []),
        };

        if (address.city) {
            const street = `${address.street} ${address.houseNumber}`.trim();
            const city = `${item.address.district && item.address.district !== item.address.city ? address.district + ', ' + address.city : address.city}`;
            return `${city} ${street ? street + ', ' : ''} ${address.postalCode ? address.postalCode : ''} ${address.countryName}`;
        }

        if (address.label) {
            return address.label;
        }

        return this.decorate(item.title, item.highlights?.title ?? []);
    }

    private decorate(part: string, highlights?: HereGeocodeHighlightPositionModel[]) {
        let name = part;
        if (highlights) {
            const positions = highlights.reduce(
                (prev, curr) => {
                    return [
                        ...prev,
                        {
                            at: curr.start,
                            type: 'start',
                        },
                        {
                            at: curr.end,
                            type: 'end',
                        },
                    ];
                },
                [] as { at: number; type: string }[],
            );

            positions.sort((a, b) => {
                return b.at - a.at;
            });

            positions.forEach((position) => {
                name = `${name.slice(0, position.at)}${position.type === 'start' ? '<span class="selected">' : '</span>'}${name.slice(position.at)}`;
            });
            return name;
        }
        return name;
    }
}
