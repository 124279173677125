import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Observable, Subscription, combineLatest, filter, forkJoin, iif, map, merge, mergeMap, startWith, switchMap, take, tap } from 'rxjs';
import { CountriesFacade } from '@app/store/countries';
import { ACCURACY_TYPES } from '@app/store/order/enums/accuracy.enum';
import { FormService } from '../../services/form.service';
import { GeoPositionModel } from '@app/store/planning/models';
import { AutosuggestClosestFacade, AutosuggestItem } from '@app/store/heremaps';

@Component({
    selector: 'app-form-address-manual',
    styleUrls: ['./form-address-manual.component.scss'],
    templateUrl: './form-address-manual.component.html',
})
export class FormAddressManualComponent implements OnDestroy, OnInit {
    private readonly formService = inject(FormService);
    private readonly countriesFacade = inject(CountriesFacade);
    private readonly autosuggestClosestFacade = inject(AutosuggestClosestFacade);

    countriesNames$ = this.countriesFacade.availableListTranslated$.pipe(map((items) => items.map((item) => item.label)));

    city$!: Observable<string>;
    street$!: Observable<string>;
    geoPosition$!: Observable<GeoPositionModel>;
    isCountryRestricted$!: Observable<boolean>;
    countriesList$!: Observable<
        {
            label: string;
            value: string;
        }[]
    >;
    private lastCityValue: string | null = null;

    get form() {
        return this.formService.form;
    }

    get formCity() {
        return this.formService.form;
    }

    private subscriptions$ = new Subscription();

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    ngOnInit(): void {
        this.autosuggestClosestFacade;
        this.lastCityValue;

        this.city$ = this.formService.form.controls.city.valueChanges.pipe(
            startWith(true),
            map(() => this.formService.form.controls.city.getRawValue() || ''),
        );

        this.street$ = this.formService.form.controls.street.valueChanges.pipe(
            startWith(true),
            map(() => this.formService.form.controls.street.getRawValue() || ''),
        );

        this.geoPosition$ = this.formService.form.controls.geoPosition.valueChanges.pipe(
            startWith(true),
            map(() => this.formService.form.controls.geoPosition.getRawValue()),
        );

        this.isCountryRestricted$ = this.formService.form.controls.countryRestricted.valueChanges.pipe(
            startWith(true),
            map(() => this.formService.form.controls.countryRestricted.getRawValue()),
        );

        this.countriesList$ = this.form.controls.countryRestricted.valueChanges.pipe(
            startWith(this.form.controls.countryRestricted.value),
            mergeMap((restricted) => iif(() => restricted === true, this.countriesFacade.availableListTranslated$, this.countriesFacade.allListTranslated$)),
        );

        // on country change
        this.subscriptions$.add(this.form.controls.country.valueChanges.pipe(tap(() => this.formService.resetByCountry())).subscribe());

        this.subscriptions$.add(
            this.countriesList$
                .pipe(
                    map((countries) => countries.map((country) => country.value)),
                    filter((values) => {
                        const country = this.form.controls.country.getRawValue();

                        return country !== null && values.includes(country) === false;
                    }),
                    tap(() => {
                        this.formService.resetByCountry();
                        this.formService.form.controls.country.patchValue('POL');
                    }),
                )
                .subscribe(),
        );

        // reset geo on empty string
        this.subscriptions$.add(
            this.formService.blurCity$
                .pipe(
                    mergeMap(() => this.city$.pipe(take(1))),
                    filter((value) => value === ''),
                    tap(() => this.lastCityValue === null),
                    tap(() => this.resetGeoLocation()),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            merge(this.formService.blurCity$, this.formService.blurStreet$)
                .pipe(
                    mergeMap(() => this.city$.pipe(take(1))),
                    filter((value) => value !== ''),
                    tap((value) => this.lastCityValue === value),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            combineLatest([
                merge(this.formService.blurCity$, this.formService.blurStreet$).pipe(
                    mergeMap(() => forkJoin([this.city$.pipe(take(1)), this.street$.pipe(take(1)), this.geoPosition$.pipe(take(1))])),
                    filter(([city, , geoPosition]) => city !== '' && geoPosition.latitude !== null),
                ),
                this.isCountryRestricted$,
                this.countriesFacade.availableCode3$,
            ])
                .pipe(
                    filter(
                        ([[street, city, geoPosition]]) =>
                            typeof street === 'string' &&
                            typeof city === 'string' &&
                            city !== '' &&
                            geoPosition.latitude !== null &&
                            geoPosition.longitude !== null,
                    ),
                    tap(([[city, street, geoPosition], restricted, countries]) => {
                        const selectedCountires = restricted ? countries : [];
                        this.autosuggestClosestFacade.getClosest(street, city, geoPosition, selectedCountires);
                    }),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            combineLatest([
                merge(this.formService.blurCity$, this.formService.blurStreet$).pipe(
                    mergeMap(() => forkJoin([this.city$.pipe(take(1)), this.street$.pipe(take(1)), this.geoPosition$.pipe(take(1))])),
                    filter(([city, , geoPosition]) => city !== '' && geoPosition.latitude !== null),
                ),
                this.isCountryRestricted$,
                this.countriesFacade.availableCode3$,
            ])
                .pipe(
                    filter(
                        ([[street, city, geoPosition]]) =>
                            typeof street === 'string' &&
                            typeof city === 'string' &&
                            city !== '' &&
                            geoPosition.latitude !== null &&
                            geoPosition.longitude !== null,
                    ),
                    switchMap(([[street, city, geoPosition], restricted, countries]) => {
                        const selectedCountires = restricted ? countries : [];
                        return this.autosuggestClosestFacade.item$(city, street, geoPosition, selectedCountires);
                    }),
                    tap((data) => {
                        this.applySelectedValue(data);
                    }),
                )
                .subscribe(),
        );
    }

    onStreetBlur() {
        this.formService.blurStreet$.next(true);
    }

    onCityBlur() {
        this.formService.blurCity$.next(true);
    }

    onCountryToggleClick(event: Event) {
        event.preventDefault();
        this.form.controls.countryRestricted.patchValue(!this.form.controls.countryRestricted.getRawValue());
    }

    onSearchTypeChangeClick(event: Event) {
        event.preventDefault();
        if (this.form.controls.accuracy.getRawValue() === ACCURACY_TYPES.HOUSE_NUMBER) {
            this.form.controls.accuracy.patchValue(ACCURACY_TYPES.STREET);
        } else {
            this.form.controls.accuracy.patchValue(ACCURACY_TYPES.HOUSE_NUMBER);
            this.form.patchValue({
                street: '',
                houseNumber: '',
            });
        }
    }

    resetGeoLocation() {
        this.form.patchValue({
            geoPosition: {
                longitude: null,
                latitude: null,
            },
        });
    }

    private applySelectedValue(item: AutosuggestItem) {
        const city = `${
            item.address.district && item.address.district !== item.address.city ? item.address.district + ', ' + item.address.city : item.address.city
        }`;
        this.form.patchValue(
            {
                id: item.id,
                isPoint: false,
                country: item.address.countryCode,
                city: city,
                postalCode: item.address.postalCode,
            },
            { emitEvent: false },
        );
        if (item.address.postalCode && item.address.street && item.address.city) {
            this.form.patchValue({
                geoPosition: {
                    longitude: item.position.lng,
                    latitude: item.position.lat,
                },
            });
        } else {
            this.form.patchValue({
                geoPosition: {
                    longitude: null,
                    latitude: null,
                },
            });
        }
    }
}
