import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Observable, Subscription, filter, iif, map, mergeMap, startWith, tap } from 'rxjs';
import { CountriesFacade } from '@app/store/countries';
import { ACCURACY_TYPES } from '@app/store/order/enums/accuracy.enum';
import { FormService } from '../../services/form.service';

@Component({
    selector: 'app-form-address-preview',
    styleUrls: ['./form-address-preview.component.scss'],
    templateUrl: './form-address-preview.component.html',
})
export class FormAddressPreviewComponent implements OnInit, OnDestroy {
    private readonly formService = inject(FormService);
    private readonly countriesFacade = inject(CountriesFacade);

    countriesNames$ = this.countriesFacade.availableListTranslated$.pipe(map((items) => items.map((item) => item.label)));
    countriesList$!: Observable<
        {
            label: string;
            value: string;
        }[]
    >;

    form = this.formService.form;

    private subscriptions$ = new Subscription();

    ngOnInit() {
        this.countriesList$ = this.form.controls.countryRestricted.valueChanges.pipe(
            startWith(this.form.controls.countryRestricted.value),
            mergeMap((restricted) => iif(() => restricted === true, this.countriesFacade.availableListTranslated$, this.countriesFacade.allListTranslated$)),
        );

        this.subscriptions$.add(this.form.controls.country.valueChanges.pipe(tap(() => this.formService.resetByCountry())).subscribe());

        this.subscriptions$.add(
            this.countriesList$
                .pipe(
                    map((countries) => countries.map((country) => country.value)),
                    filter((values) => {
                        const country = this.form.controls.country.getRawValue();

                        return country !== null && values.includes(country) === false;
                    }),
                    tap(() => {
                        this.formService.resetByCountry();
                        this.formService.form.controls.country.patchValue('POL');
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    onCountryToggleClick(event: Event) {
        event.preventDefault();
        this.form.controls.countryRestricted.patchValue(!this.form.controls.countryRestricted.getRawValue());
    }

    onSearchTypeChangeClick(event: Event) {
        event.preventDefault();
        if (this.form.controls.accuracy.getRawValue() === ACCURACY_TYPES.HOUSE_NUMBER) {
            this.form.controls.accuracy.patchValue(ACCURACY_TYPES.STREET);
        } else {
            this.form.controls.accuracy.patchValue(ACCURACY_TYPES.HOUSE_NUMBER);
            this.form.patchValue({
                street: '',
                houseNumber: '',
            });
        }
    }
}
