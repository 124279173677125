import { Injectable } from '@angular/core';
import { AddressFormGroup } from '../models';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { AutosuggestItem } from '@app/store/heremaps';

@Injectable()
export class FormService {
    form!: FormGroup<AddressFormGroup>;
    blurStreet$ = new Subject<boolean>();
    blurCity$ = new Subject<boolean>();

    onSelect(item: AutosuggestItem) {
        const city = `${
            item.address.district && item.address.district !== item.address.city ? item.address.district + ', ' + item.address.city : item.address.city
        }`;

        this.form.patchValue(
            {
                id: item.id,
                isPoint: false,
                term: item.title,
                country: item.address.countryCode,
                street: item.address.street,
                houseNumber: item.address.houseNumber,
                city: city,
                postalCode: item.address.postalCode,
            },
            { emitEvent: false },
        );

        if (item.address.postalCode && item.address.street) {
            this.form.patchValue(
                {
                    geoPosition: {
                        longitude: item.position.lng,
                        latitude: item.position.lat,
                    },
                },
                { emitEvent: true },
            );
        } else {
            this.form.patchValue(
                {
                    geoPosition: {
                        longitude: null,
                        latitude: null,
                    },
                },
                { emitEvent: true },
            );
        }
    }

    reset() {
        this.form.patchValue({
            id: '',
            isPoint: false,
            country: '',
            city: '',
            street: '',
            houseNumber: '',
            postalCode: '',
            geoPosition: {
                latitude: null,
                longitude: null,
            },
        });
    }

    resetByCountry() {
        this.form.patchValue({
            id: '',
            isPoint: false,
            city: '',
            street: '',
            houseNumber: '',
            postalCode: '',
            geoPosition: {
                latitude: null,
                longitude: null,
            },
        });
    }
}
