import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, share } from 'rxjs';
import { FavouriteAddressesAction } from '../actions/favourite-addresses.actions';
import { selectFavouriteAddresses, selectLoaded, selectLoading } from '../selectors/favourite-addresses.selectors';
import { FavouriteAddress } from '../models';
import { UserFacade } from '@app/modules';
import { Roles } from '@app/modules/permissions';

@Injectable({
    providedIn: 'root',
})
export class FavouriteAddressesFacade {
    private readonly userFacade = inject(UserFacade);

    favouriteAddresses$ = this.store.select(selectFavouriteAddresses).pipe(filter((favouriteAddresses) => favouriteAddresses !== null));
    isAnyFavouriteAddressAdded$ = this.favouriteAddresses$.pipe(
        map((addresses) => (addresses?.length ?? 0) > 0),
        share(),
    );
    loaded$ = this.store.select(selectLoaded).pipe(filter((loaded) => loaded !== null));
    loading$ = this.store.select(selectLoading).pipe(filter((loading) => loading !== null));

    constructor(private store: Store) {}

    getFavouriteAddresses(): void {
        if (this.userFacade.state.user?.role === Roles.ROLE_PARTNER_EMPLOYEE) {
            this.store.dispatch(FavouriteAddressesAction.getFavouriteAdresses());
        }
    }

    addFavouriteAddress(name: string, searchValue: string, value: FavouriteAddress): void {
        this.store.dispatch(FavouriteAddressesAction.addFavouriteAddress({ name, searchValue, value }));
    }

    updateFavouriteAddress(id: string, name: string, searchValue: string, value: FavouriteAddress): void {
        this.store.dispatch(FavouriteAddressesAction.updateFavouriteAddress({ id, name, searchValue, value }));
    }

    deleteFavouriteAddress(id: string): void {
        this.store.dispatch(FavouriteAddressesAction.deleteFavouriteAddress({ id }));
    }

    clear(): void {
        this.store.dispatch(FavouriteAddressesAction.clear());
    }
}
