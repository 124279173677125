import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormValidators } from '@app/shared/forms';
import { OpenClose, PopupBaseComponent } from '@app/shared/popup';
import { GeoPositionModel } from '@app/store/planning/models';
import { Subscription } from 'rxjs';
import { FavouriteAddress, FavouriteAddressSearch, FavouriteAddressesFacade } from '@app/store/favourite-addresses';

@Component({
    selector: 'app-popup-save',
    templateUrl: './popup-save.component.html',
    styleUrls: ['./popup-save.component.scss'],
    animations: [OpenClose],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupSaveComponent extends PopupBaseComponent implements OnInit, OnDestroy {
    private readonly favouriteAddressesFacade = inject(FavouriteAddressesFacade);

    private formBuilder = inject(FormBuilder);
    private readonly subscriptions$ = new Subscription();

    form = this.formBuilder.group({
        address: this.formBuilder.group({
            city: this.formBuilder.control('', { nonNullable: true }),
            street: this.formBuilder.control('', { nonNullable: true }),
            houseNumber: this.formBuilder.control('', { nonNullable: true }),
            postalCode: this.formBuilder.control('', { nonNullable: true }),
            country: this.formBuilder.control('', { nonNullable: true }),
            geoPosition: this.formBuilder.control<GeoPositionModel>(
                {
                    latitude: null,
                    longitude: null,
                },
                { nonNullable: true },
            ),
        }),
        searchValue: this.formBuilder.control('', { validators: [] }),
        name: this.formBuilder.control('', { validators: [FormValidators.Required, FormValidators.MaxLength(255), FormValidators.Text] }),
    });

    override readonly data!: FavouriteAddressSearch;

    ngOnInit(): void {
        this.form.patchValue({
            address: { ...this.data.address },
            name: '',
            searchValue: this.data.searchValue,
        });
        this.form.controls.address.disable();
        this.form.controls.searchValue.disable();
    }

    override ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
        super.ngOnDestroy();
    }

    onCloseClick(event: Event): void {
        event.preventDefault();
        this.close('close');
    }

    onSaveClick(event: SubmitEvent): void {
        event.preventDefault();
        this.form.markAllAsTouched();

        if (!this.form.valid) {
            return;
        }

        this.favouriteAddressesFacade.addFavouriteAddress(this.form.controls.name.value ?? '', this.form.controls.searchValue.value ?? '', {
            city: this.form.controls.address.controls.city.value ?? '',
            street: this.form.controls.address.controls.street.value ?? '',
            houseNumber: this.form.controls.address.controls.houseNumber.value ?? '',
            postalCode: this.form.controls.address.controls.postalCode.value ?? '',
            country: this.form.controls.address.controls.country.value ?? '',
            geoPosition: this.form.controls.address.controls.geoPosition.getRawValue(),
        } as FavouriteAddress);
        this.close('save');
    }

    onCloseEvent(reason: string) {
        this.close(reason);
    }
}
