import { Component, Input, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PopupSaveComponent } from '../popup-save/popup-save.component';
import { PopupListComponent } from '../popup-list/popup-list.component';
import { AddressFormGroup } from '../../models';
import { PopupService } from '@app/shared/popup';
import { FavouriteAddressSearch, FavouriteAddressesFacade } from '@app/store/favourite-addresses';

@Component({
    selector: 'app-form-address-favourites',
    templateUrl: './form-address-favourites.component.html',
    styleUrls: ['./form-address-favourites.component.scss'],
})
export class FormAddressFavouritesComponent {
    private readonly popupService = inject(PopupService);
    private readonly favouriteAddressesFacade = inject(FavouriteAddressesFacade);

    @Input() formGroup!: FormGroup<AddressFormGroup>;

    isAnyFavouriteAddressAdded$ = this.favouriteAddressesFacade.isAnyFavouriteAddressAdded$;

    get canAddressBeAddedToFavourites() {
        const { city, street, geoPosition, houseNumber } = this.formGroup.controls;
        return (
            this.formGroup.valid &&
            (!!city?.value?.length ||
                !!street?.value?.length ||
                !!geoPosition?.value?.latitude ||
                !!geoPosition?.value?.longitude ||
                !!houseNumber?.value?.length)
        );
    }

    openSaveFavouriteAddressPopup() {
        const addressData = {
            searchValue: this.formGroup.controls.term.value,
            address: {
                country: this.formGroup.controls.country.value,
                city: this.formGroup.controls.city.value,
                street: this.formGroup.controls.street.value,
                houseNumber: this.formGroup.controls.houseNumber.value,
                postalCode: this.formGroup.controls.postalCode.value,
                geoPosition: this.formGroup.controls.geoPosition.getRawValue(),
            },
        } as FavouriteAddressSearch;

        this.popupService.open(PopupSaveComponent, addressData);
    }

    openManageFavouriteAddressPopup() {
        this.popupService.open(PopupListComponent, this.formGroup);
    }
}
