import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { FavouriteAddress, FavouriteAddressFull } from '../models/favourite-addresses.model';

export const FavouriteAddressesAction = createActionGroup({
    source: 'FavouriteAddresses',
    events: {
        'Get Favourite Adresses': emptyProps(),
        'Get Favourite Adresses Start': emptyProps(),
        'Get Favourite Adresses Success': props<{ items: FavouriteAddressFull[] }>(),
        'Get Favourite Adresses Error': props<{ error: HttpErrorResponse }>(),
        'Add Favourite Address': props<{ name: string; searchValue: string; value: FavouriteAddress }>(),
        'Add Favourite Address Success': props<{ item: FavouriteAddressFull }>(),
        'Add Favourite Address Error': props<{ error: HttpErrorResponse }>(),
        'Update Favourite Address': props<{ id: string; name: string; searchValue: string; value: FavouriteAddress }>(),
        'Update Favourite Address Success': props<{ address: FavouriteAddress }>(),
        'Update Favourite Address Error': props<{ error: HttpErrorResponse }>(),
        'Delete Favourite Address': props<{ id: string }>(),
        'Delete Favourite Address Success': props<{ id: string }>(),
        'Delete Favourite Address Error': props<{ error: HttpErrorResponse }>(),
        Clear: emptyProps(),
    },
});
