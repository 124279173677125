<div class="box-popup" id="popup">
    <div (click)="onCloseClick($event)" (keypress)="onCloseClick($event)" tabindex="1" class="popup-close-btn"></div>
    <div class="popup-inside">
        <div class="title fw-600">Ulubione adresy</div>
        <div *ngIf="favouriteAddresses$ | async as favouriteAddresses">
            <app-popup-list-item
                *ngFor="let favouriteAddress of favouriteAddresses"
                [favouriteAddress]="favouriteAddress"
                (deleteClicked)="onDeleteClick($event.id)"
                (selectClicked)="onSelectClick($event)"
                class="mb-3 d-block"
            ></app-popup-list-item>
            <p *ngIf="favouriteAddresses?.length === 0" class="p-my-2">Brak zapisanych adresów</p>
        </div>
    </div>
</div>
