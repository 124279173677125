import { Component, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { Validators } from '@angular/forms';
import {
    BehaviorSubject,
    Observable,
    Subject,
    Subscription,
    combineLatest,
    debounceTime,
    delay,
    filter,
    iif,
    map,
    of,
    skip,
    startWith,
    switchMap,
    tap,
} from 'rxjs';
import { AutosuggestFacade } from '@app/store/heremaps';
import { CountriesFacade } from '@app/store/countries';
import { AutosuggestItem } from '@app/store/heremaps';
import { AutoComplete, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { ShowHide } from '@app/shared/animations';
import { UserFacade } from '@app/modules';
import { Roles } from '@app/modules/permissions';
import { FormService } from '../../services/form.service';

@Component({
    selector: 'app-form-autosuggest-input',
    styleUrls: ['./form-autosuggest-input.component.scss'],
    templateUrl: './form-autosuggest-input.component.html',
    animations: [ShowHide],
})
export class FormAutosuggestInputComponent implements OnInit, OnDestroy {
    private readonly formService = inject(FormService);
    private readonly autosuggestFacade = inject(AutosuggestFacade);
    private readonly countriesFacade = inject(CountriesFacade);
    private readonly userFacade = inject(UserFacade);

    @ViewChild('autocomplete') autocomplete?: AutoComplete;
    @Input() placeholder?: string;

    items$!: Observable<AutosuggestItem[]>;
    loading$!: Observable<boolean>;
    hasGeoposition$!: Observable<boolean>;
    areFavouriteAddressesAvailable = this.userFacade.state.user?.role === Roles.ROLE_PARTNER_EMPLOYEE;

    get form() {
        return this.formService.form;
    }

    term$!: Observable<string>;
    isCountryRestricted$!: Observable<boolean>;
    focus$ = new BehaviorSubject(null);
    selected$ = new Subject<void>();

    private readonly subscriptions$ = new Subscription();

    ngOnInit() {
        this.term$ = combineLatest([this.formService.form.controls.term.valueChanges.pipe(startWith(true)), this.focus$]).pipe(
            filter(() => typeof this.formService.form.controls.term.getRawValue() === 'string'),
            map(() => this.formService.form.controls.term.getRawValue().trim()),
        );

        this.isCountryRestricted$ = this.formService.form.controls.countryRestricted.valueChanges.pipe(
            startWith(true),
            map(() => this.formService.form.controls.countryRestricted.getRawValue()),
        );

        this.items$ = combineLatest([this.term$, this.isCountryRestricted$, this.countriesFacade.availableCode3$]).pipe(
            switchMap(([term, restricted, countries]) =>
                iif(() => typeof term === 'string' && term !== '', this.autosuggestFacade.items$(term, restricted ? countries : []), of([])),
            ),
        );

        this.loading$ = combineLatest([this.term$, this.isCountryRestricted$, this.countriesFacade.availableCode3$]).pipe(
            switchMap(([term, restricted, countries]) => this.autosuggestFacade.loading$(term, restricted ? countries : [])),
        );

        this.subscriptions$.add(
            combineLatest([this.term$.pipe(skip(1), debounceTime(0)), this.isCountryRestricted$, this.countriesFacade.availableCode3$])
                .pipe(
                    filter(([term]) => typeof term === 'string'),
                    debounceTime(300),
                    tap(([term, restricted, countries]) => this.autosuggestFacade.getAutosuggests(term, restricted ? countries : [])),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.term$
                .pipe(
                    filter((term) => typeof term === 'string' && term !== ''),
                    tap(() => this.autocomplete?.show()),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.selected$
                .pipe(
                    delay(10),
                    tap(() => {
                        this.autocomplete?.hide();
                        this.autocomplete?.inputEL?.nativeElement.blur();
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    onSelect(event: AutoCompleteSelectEvent) {
        const item: AutosuggestItem = event.value;
        this.formService.onSelect(item);
        this.selected$.next();
    }

    onFocus() {
        this.focus$.next(null);
        this.autocomplete?.show();
        // if (this.formService.form.controls.term.getRawValue() !== '') {

        // }
    }

    get required(): boolean {
        return this.formService.form.controls.term.hasValidator(Validators.required);
    }
}
