import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, mergeMap, tap } from 'rxjs';
import { GuiFacade } from '@app/gui';
import { ResponseError } from '@app/shared/models/api-response.dtos';
import { HttpErrorResponse } from '@angular/common/http';
import { FavouriteAddressesAction } from '../actions/favourite-addresses.actions';
import { HttpService } from '@app/store/favourite-addresses/services/http.service';

@Injectable()
export class FavouriteAddressesEffects {
    getFavouriteAddresses$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                FavouriteAddressesAction.getFavouriteAdresses,
                FavouriteAddressesAction.updateFavouriteAddressSuccess,
                FavouriteAddressesAction.addFavouriteAddressSuccess,
            ),
            map(() => FavouriteAddressesAction.getFavouriteAdressesStart()),
        );
    });

    getFavouriteAddressesStart$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FavouriteAddressesAction.getFavouriteAdressesStart),
            mergeMap(() => {
                return this.httpService.getFavouriteAddresses().pipe(
                    map((response) => FavouriteAddressesAction.getFavouriteAdressesSuccess({ items: response })),
                    catchError((error: HttpErrorResponse) => of(FavouriteAddressesAction.getFavouriteAdressesError({ error }))),
                );
            }),
        );
    });

    getFavouriteAddressesSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(ofType(FavouriteAddressesAction.getFavouriteAdressesSuccess));
        },
        {
            dispatch: false,
        },
    );

    getFavouriteAddressesError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(FavouriteAddressesAction.getFavouriteAdressesError),
                tap((action) => {
                    const apiError: ResponseError[] = (<{ errors: ResponseError[] }>action.error.error).errors;
                    if (action.error.status === 400) {
                        if (apiError && apiError.length) {
                            let errorMsg = '';
                            apiError.forEach((er) => {
                                errorMsg = `${er.title} `;
                                this.guiFacade.showErrorMessage(errorMsg);
                            });
                        }
                    }
                }),
            );
        },
        { dispatch: false },
    );

    addFavouriteAddress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FavouriteAddressesAction.addFavouriteAddress),
            mergeMap((action) => {
                return this.httpService.saveFavouriteAddress(action.name, action.searchValue, action.value).pipe(
                    map((response) => FavouriteAddressesAction.addFavouriteAddressSuccess({ item: response })),
                    catchError((error: HttpErrorResponse) => of(FavouriteAddressesAction.addFavouriteAddressError({ error }))),
                );
            }),
        );
    });

    addFavouriteAddressSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(FavouriteAddressesAction.addFavouriteAddressSuccess),
                tap(() => {
                    this.guiFacade.showSuccessMessage('GUI.TOAST.saved');
                }),
            );
        },
        { dispatch: false },
    );

    addFavouriteAddressError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(FavouriteAddressesAction.addFavouriteAddressError),
                tap((action) => {
                    const apiError: ResponseError[] = (action.error?.error?.errors as ResponseError[]) ?? [];
                    if (action.error.status === 422) {
                        if (apiError && apiError.length) {
                            let errorMsg = '';
                            apiError.forEach((er) => {
                                errorMsg = `${er.title} ${er.propertyPath as string}`;
                                this.guiFacade.showErrorMessage(errorMsg);
                            });
                        }
                    }
                }),
            );
        },
        { dispatch: false },
    );

    updateFavouriteAddress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FavouriteAddressesAction.updateFavouriteAddress),
            mergeMap((action) => {
                return this.httpService.updateFavouriteAddress(action.id, action.name, action.searchValue, action.value).pipe(
                    map((response) => FavouriteAddressesAction.updateFavouriteAddressSuccess({ address: response })),
                    catchError((error: HttpErrorResponse) => of(FavouriteAddressesAction.updateFavouriteAddressError({ error }))),
                );
            }),
        );
    });

    updateFavouriteAddressSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(ofType(FavouriteAddressesAction.updateFavouriteAddressSuccess));
        },
        {
            dispatch: false,
        },
    );

    updateFavouriteAddressError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(FavouriteAddressesAction.updateFavouriteAddressError),
                tap((action) => {
                    const apiError: ResponseError[] = action?.error?.error?.errors as ResponseError[];
                    if (action.error.status === 422) {
                        if (apiError && apiError.length) {
                            let errorMsg = '';
                            apiError.forEach((er) => {
                                errorMsg = `${er.title} ${er.propertyPath as string}`;
                                this.guiFacade.showErrorMessage(errorMsg);
                            });
                        }
                    }
                }),
            );
        },
        { dispatch: false },
    );

    deleteFavouriteAddress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(FavouriteAddressesAction.deleteFavouriteAddress),
            mergeMap((action) => {
                return this.httpService.deleteFavouriteAddress(action.id).pipe(
                    map(() => FavouriteAddressesAction.deleteFavouriteAddressSuccess({ id: action.id })),
                    catchError((error: HttpErrorResponse) => of(FavouriteAddressesAction.updateFavouriteAddressError({ error }))),
                );
            }),
        );
    });

    deleteFavouriteAddressSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(FavouriteAddressesAction.updateFavouriteAddressSuccess),
                tap(() => {
                    this.guiFacade.showSuccessMessage('GUI.TOAST.saved');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    deleteFavouriteAddressError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(FavouriteAddressesAction.updateFavouriteAddressError),
                tap((action) => {
                    const apiError: ResponseError[] = action?.error?.error?.errors as ResponseError[];
                    if (action.error.status === 422) {
                        if (apiError && apiError.length) {
                            let errorMsg = '';
                            apiError.forEach((er) => {
                                errorMsg = `${er.title} ${er.propertyPath as string}`;
                                this.guiFacade.showErrorMessage(errorMsg);
                            });
                        }
                    }
                }),
            );
        },
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private httpService: HttpService,
        private guiFacade: GuiFacade,
    ) {}
}
