<p-panel [styleClass]="isClosed ? 'is-closed' : ''">
    <ng-template pTemplate="header">
        <div class="d-flex w-100 align-items-center">
            <span class="flex-grow-1 header">{{ favouriteAddress.name }}</span>
            <p-button (click)="onMoreClick($event)" class="ml-auto" [text]="true" icon="pi pi-info-circle" iconPos="left" />
            <p-button (click)="onSelectClick($event)" class="ml-auto" [text]="true" icon="pi pi-chevron-circle-right" iconPos="left" />
        </div>
    </ng-template>
    <div>
        <p class="p-my-2"><b>Wyszukiwana fraza:</b> {{ favouriteAddress.searchValue }}</p>
        <p class="p-my-2"><b>Ulica:</b> {{ favouriteAddress.address.street }}</p>
        <p class="p-my-2"><b>Nr domu:</b> {{ favouriteAddress.address.flatNumber ?? '-' }}</p>
        <p class="p-my-2"><b>Kod poczt.:</b> {{ favouriteAddress.address.postalCode }}</p>
        <p class="p-my-2"><b>Miejscowość:</b> {{ favouriteAddress.address.city }}</p>
        <p class="p-my-2"><b>Kraj:</b> {{ favouriteAddress.address.country }}</p>
    </div>
    <p-divider></p-divider>

    <div class="d-flex align-items-start">
        <form [formGroup]="form" class="me-3">
            <app-form-input [block]="true" formControlGroup="newName" type="text"></app-form-input>
        </form>
        <p-button (click)="onChangeNameClick($event)" styleClass="p-button-success" label="Zmień nazwę" type="submit"></p-button>
        <div class="ms-auto">
            <p-button (click)="onDeleteClick($event)" styleClass="p-button-danger" icon="pi pi-trash" iconPos="left" type="button"></p-button>
        </div>
    </div>
</p-panel>
