import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FAVOURITE_ADDRESSES_FEATURE_KEY } from './keys';
import { favouriteAddressesReducer } from './reducers/favourite-addresses.reducers';
import { FavouriteAddressesEffects } from './effects/favourite-addresses.effects';

@NgModule({
    imports: [StoreModule.forFeature(FAVOURITE_ADDRESSES_FEATURE_KEY, favouriteAddressesReducer), EffectsModule.forFeature([FavouriteAddressesEffects])],
})
export class FavouriteAddressesModule {}
