<div class="d-flex align-items-center">
    <div class="position-relative w-100" [formGroup]="form">
        <p-autoComplete
            #autocomplete
            styleClass="w-100"
            placeholder="Wyszukaj adres"
            inputStyleClass="w-100"
            formControlName="term"
            dataKey="id"
            [suggestions]="(items$ | async)!"
            (onFocus)="onFocus()"
            (onSelect)="onSelect($event)"
            [showEmptyMessage]="true"
            [autoOptionFocus]="false"
            [ngClass]="{
                'ng-invalid ng-dirty': form.controls.term.touched && form.controls.term.invalid,
            }"
        >
            <ng-template let-option pTemplate="item">
                <div class="d-flex align-items-center">
                    <div [innerHTML]="option | autosuggesstAddress"></div>
                </div>
            </ng-template>
            <ng-template let-option pTemplate="empty">
                <div>Brak wyników wyszukiwania</div>
            </ng-template>
            <ng-template let-option pTemplate="loadingicon"> </ng-template>
        </p-autoComplete>
        <div *ngIf="((loading$ | async) || false) && form.controls.term.value !== ''" class="loader"></div>
    </div>
    <ng-content></ng-content>
</div>
