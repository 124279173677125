<div class="d-flex align-items-center">
    <div class="position-relative w-100" [formGroup]="form">
        <div class="fs-14 fw-600 lh-1 mb-2">Miejscowość</div>
        <div class="position-relative">
            <p-autoComplete
                #autocomplete
                styleClass="w-100"
                placeholder="Wyszukaj adres"
                inputStyleClass="w-100"
                formControlName="city"
                dataKey="id"
                [suggestions]="(items$ | async) || []"
                (onFocus)="onFocus()"
                (onBlur)="onBlur()"
                (onSelect)="onSelect($event)"
                emptyMessage="Brak wyników wyszukiwania"
                [showEmptyMessage]="true"
                [ngClass]="{
                    'ng-invalid ng-dirty': form.controls.term.touched && form.controls.term.invalid
                }"
            >
                <ng-template let-option pTemplate="item">
                    <div class="d-flex align-items-center">
                        <div [innerHTML]="option | autosuggesstAddress"></div>
                    </div>
                </ng-template>
                <ng-template let-option pTemplate="loadingicon"> </ng-template>
            </p-autoComplete>
            <div *ngIf="((loading$ | async) || false) && form.controls.term.value !== ''" class="loader"></div>
        </div>
    </div>
    <ng-content></ng-content>
</div>
