import { createReducer, on } from '@ngrx/store';
import { FavouriteAddressesAction } from '../actions/favourite-addresses.actions';
import { FavouriteAddressFull } from '../models/favourite-addresses.model';

export interface FavouriteAddressesState {
    favouriteAddresses: FavouriteAddressFull[];
    loading: boolean | null;
    loaded: boolean | null;
}

export const initialState: FavouriteAddressesState = {
    favouriteAddresses: [],
    loading: null,
    loaded: null,
};

export const favouriteAddressesReducer = createReducer(
    initialState,
    on(
        FavouriteAddressesAction.getFavouriteAdresses,
        (state): FavouriteAddressesState => ({
            ...state,
        }),
    ),
    on(
        FavouriteAddressesAction.getFavouriteAdressesStart,
        (state): FavouriteAddressesState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        FavouriteAddressesAction.getFavouriteAdressesSuccess,
        (state, action): FavouriteAddressesState => ({
            ...state,
            loading: false,
            loaded: true,
            favouriteAddresses: action.items,
        }),
    ),
    on(
        FavouriteAddressesAction.getFavouriteAdressesError,
        (state): FavouriteAddressesState => ({
            ...state,
            loading: false,
        }),
    ),
    on(FavouriteAddressesAction.addFavouriteAddress, (state, action): FavouriteAddressesState => {
        const newItem = {
            id: null,
            name: null,
            partnerId: null,
            address: { ...action.value },
        } as FavouriteAddressFull;

        return {
            ...state,
            favouriteAddresses: [...state.favouriteAddresses, newItem],
        };
    }),
    on(FavouriteAddressesAction.addFavouriteAddressSuccess, (state): FavouriteAddressesState => {
        return {
            ...state,
            favouriteAddresses: state.favouriteAddresses.map((item) => {
                return {
                    ...item,
                    saving: false,
                };
            }),
        };
    }),
    on(FavouriteAddressesAction.addFavouriteAddressError, (state): FavouriteAddressesState => {
        return {
            ...state,
            favouriteAddresses: state.favouriteAddresses.map((item) => ({
                ...item,
                saving: false,
                saved: false,
            })),
        };
    }),
    on(FavouriteAddressesAction.updateFavouriteAddress, (state, action): FavouriteAddressesState => {
        return {
            ...state,
            favouriteAddresses: state.favouriteAddresses.map((item) =>
                item.id === action.id
                    ? {
                          ...item,
                          saving: true,
                      }
                    : item,
            ),
        };
    }),

    on(FavouriteAddressesAction.updateFavouriteAddressSuccess, (state, action): FavouriteAddressesState => {
        return {
            ...state,
            favouriteAddresses: state.favouriteAddresses.map((item) =>
                item.id !== null && item.id === action.address.id
                    ? {
                          ...item,
                          saving: false,
                          saved: action.address,
                      }
                    : item,
            ),
        };
    }),

    on(FavouriteAddressesAction.updateFavouriteAddressError, (state): FavouriteAddressesState => {
        return {
            ...state,
            favouriteAddresses: state.favouriteAddresses.map((item) =>
                item.id
                    ? {
                          ...item,
                          saving: false,
                          saved: false,
                      }
                    : item,
            ),
        };
    }),
    on(FavouriteAddressesAction.deleteFavouriteAddress, (state, action): FavouriteAddressesState => {
        return {
            ...state,
            favouriteAddresses: state.favouriteAddresses.filter((address) => address.id !== action.id),
            loading: true,
            loaded: false,
        };
    }),

    on(FavouriteAddressesAction.deleteFavouriteAddressSuccess, (state, action): FavouriteAddressesState => {
        return {
            ...state,
            favouriteAddresses: state.favouriteAddresses.filter((address) => address.id !== action.id),
            loading: false,
            loaded: true,
        };
    }),

    on(FavouriteAddressesAction.deleteFavouriteAddressError, (state): FavouriteAddressesState => {
        return {
            ...state,
            favouriteAddresses: state.favouriteAddresses.map((item) =>
                item.id
                    ? {
                          ...item,
                          saving: false,
                          saved: false,
                      }
                    : item,
            ),
        };
    }),
    on(
        FavouriteAddressesAction.clear,
        (state): FavouriteAddressesState => ({
            ...state,
            ...initialState,
        }),
    ),
);
