import { Component, OnDestroy, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OpenClose, PopupBaseComponent } from '@app/shared/popup';
import { FavouriteAddressFull, FavouriteAddressesFacade } from '@app/store/favourite-addresses';
import { Subscription } from 'rxjs';
import { AddressFormGroup } from '../../models';

@Component({
    selector: 'app-popup-list',
    templateUrl: './popup-list.component.html',
    styleUrls: ['./popup-list.component.scss'],
    animations: [OpenClose],
})
export class PopupListComponent extends PopupBaseComponent implements OnDestroy {
    private readonly subscriptions$ = new Subscription();
    private readonly favouriteAddressesFacade = inject(FavouriteAddressesFacade);

    override readonly data!: FormGroup<AddressFormGroup>;

    favouriteAddresses$ = this.favouriteAddressesFacade.favouriteAddresses$;
    loaded$ = this.favouriteAddressesFacade.loaded$;

    override ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
        super.ngOnDestroy();
    }

    onCloseClick(event: Event): void {
        event.preventDefault();
        this.close('close');
    }

    onDeleteClick(addressId?: string | null): void {
        if (typeof addressId === 'undefined' || addressId === null) {
            return;
        }
        this.favouriteAddressesFacade.deleteFavouriteAddress(addressId);
    }

    onSelectClick(address?: FavouriteAddressFull): void {
        if (typeof address === 'undefined' || address === null) {
            return;
        }
        this.data.controls.term.setValue(address.searchValue);
        this.data.controls.city.setValue(address.address.city);
        this.data.controls.country.setValue(address.address.country);
        this.data.controls.houseNumber.setValue(address.address.houseNumber);
        this.data.controls.id.setValue(address.address.id);
        this.data.controls.postalCode.setValue(address.address.postalCode);
        this.data.controls.street.setValue(address.address.street);
        this.data.controls.geoPosition.setValue(address.address.geoPosition);
        this.onCloseEvent('selected');
    }

    onCloseEvent(reason: string) {
        this.close(reason);
    }
}
