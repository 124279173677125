import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { OpenClose } from '@app/shared/popup/animation/popup.animation';
import { PopupBaseComponent } from '@app/shared/popup/class/popup.class';
import { GeoPositionModel } from '@app/store/planning/models';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-map-preview',
    templateUrl: './popup-map-preview.component.html',
    styleUrls: ['./popup-map-preview.component.scss'],
    animations: [OpenClose],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupMapPreviewComponent extends PopupBaseComponent implements OnDestroy {
    override readonly data!: GeoPositionModel;

    private readonly subscriptions$ = new Subscription();

    override ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
        super.ngOnDestroy();
    }

    onCloseClick(event: Event): void {
        event.preventDefault();
        this.close('close');
    }

    onCloseEvent(reason: string) {
        this.close(reason);
    }
}
