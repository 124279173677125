<div class="box-popup" id="popup">
    <div (click)="onCloseClick($event)" (keypress)="onCloseClick($event)" tabindex="1" class="popup-close-btn"></div>
    <div class="title fw-600">Dodaj do ulubionych adresów</div>
    <form [formGroup]="form" (submit)="onSaveClick($event)">
        <div class="row mb-5">
            <div class="col d-flex">
                <div class="w-100">
                    <app-form-input [block]="true" formControlGroup="name" [label]="'Nazwa adresu'" [clearIcon]="true"></app-form-input>
                </div>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col d-flex">
                <div class="w-100">
                    <app-form-input [block]="true" formControlGroup="searchValue" [label]="'Wyszukiwana fraza'" [clearIcon]="true"></app-form-input>
                </div>
            </div>
        </div>
        <div class="row">
            <form formGroupName="address">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="mb-5">
                            <app-form-input formControlGroup="street" [block]="true" [label]="'BOOKINGS.FORM.street' | translate"></app-form-input>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="mb-5">
                            <app-form-input formControlGroup="houseNumber" [block]="true" [label]="'BOOKINGS.FORM.house_number' | translate"></app-form-input>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="mb-5">
                            <app-form-input formControlGroup="postalCode" [block]="true" [label]="'BOOKINGS.FORM.postal_code' | translate"></app-form-input>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div>
                            <app-form-input formControlGroup="city" [block]="true" [label]="'BOOKINGS.FORM.city' | translate"> </app-form-input>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div>
                            <app-form-input formControlGroup="country" [block]="true" [label]="'BOOKINGS.FORM.country' | translate"> </app-form-input>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="mt-12">
            <div class="d-flex justify-content-end">
                <p-button (click)="onCloseClick($event)" label="Anuluj" type="button"></p-button>
                <div class="ms-4">
                    <p-button label="Dodaj do ulubionych" icon="pi pi-star" iconPos="left" type="submit"></p-button>
                </div>
            </div>
        </div>
    </form>
</div>
